import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircleIcon, PlusIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import WorkspaceModal from './WorkspaceModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import Api from '../utils/api';

const Workspaces = ({ project, selectedWorkspace, centered = false }) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [workspaceToDelete, setWorkspaceToDelete] = useState(null);
  const [workspaceToEdit, setWorkspaceToEdit] = useState(null);
  const [contextMenu, setContextMenu] = useState({ isOpen: false, position: { x: 0, y: 0 }, workspace: null });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const data = await Api.getWorkspaces(project.id);
        setWorkspaces(data);
      } catch (error) {
        console.error('Error fetching workspaces:', error);
      }
    };

    if (project) {
      fetchWorkspaces();
    }
  }, [project]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenu.isOpen && !event.target.closest('.context-menu')) {
        setContextMenu({ ...contextMenu, isOpen: false });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contextMenu]);

  const openContextMenu = (event, workspace) => {
    event.preventDefault();
    const rect = event.currentTarget.getBoundingClientRect();
    setContextMenu({
      isOpen: true,
      position: {
        x: rect.right - 150,
        y: rect.bottom
      },
      workspace: workspace
    });
  };

  const handleOpenModal = (workspace = null) => {
    setWorkspaceToEdit(workspace);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setWorkspaceToEdit(null);
  };

  const handleSaveWorkspace = async (title) => {
    try {
      if (workspaceToEdit) {
        const updatedWorkspace = await Api.updateWorkspace(project.id, workspaceToEdit.id, { title });
        setWorkspaces(prevWorkspaces =>
          prevWorkspaces.map(w => w.id === updatedWorkspace.id ? updatedWorkspace : w)
        );
      } else {
        const newWorkspace = await Api.addWorkspace(project.id, title);
        setWorkspaces([...workspaces, newWorkspace]);
        navigate(`/projects/${project.id}/workspaces/${newWorkspace.id}`);
      }
    } catch (error) {
      console.error('Error saving workspace:', error);
    }
  };

  const handleWorkspaceSelect = (workspace) => {
    navigate(`/projects/${project.id}/workspaces/${workspace.id}`);
  };

  const handleDeleteWorkspace = async () => {
    if (workspaceToDelete) {
      try {
        await Api.deleteWorkspace(project.id, workspaceToDelete.id);
        setWorkspaces(workspaces.filter(w => w.id !== workspaceToDelete.id));
        if (selectedWorkspace && selectedWorkspace.id === workspaceToDelete.id) {
          navigate(`/projects/${project.id}`);
        }
      } catch (error) {
        console.error('Error deleting workspace:', error);
      }
    }
    setIsDeleteModalOpen(false);
    setWorkspaceToDelete(null);
  };

  const openDeleteModal = (workspace) => {
    setWorkspaceToDelete(workspace);
    setIsDeleteModalOpen(true);
  };
  if (centered) {
    return (
      <div className="max-w-4xl mx-auto p-8 h-[90vh] overflow-hidden flex flex-col">
        <div className="text-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900 mb-2">{project.name}</h1>
        </div>
        <div className="overflow-y-auto flex-grow">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 pb-4">
            {workspaces.map((workspace) => (
              <div key={workspace.id} className="relative">
                <button
                  className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-200 ease-in-out text-left w-full border border-gray-200 hover:border-blue-500"
                  onClick={() => handleWorkspaceSelect(workspace)}
                >
                  <h3 className="text-xl font-semibold text-gray-800">{workspace.title}</h3>
                </button>
                <button
                  onClick={(e) => openContextMenu(e, workspace)}
                  className="absolute top-2 right-2 p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  <DotsVerticalIcon className="h-5 w-5" />
                </button>
              </div>
            ))}
            <button
              onClick={() => handleOpenModal(null)}
              className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-200 ease-in-out text-left w-full border border-gray-200 hover:border-blue-500 flex items-center justify-center"
            >
              <PlusIcon className="h-6 w-6 text-gray-400 mr-2" />
              <span className="text-lg font-semibold text-gray-600">New Workspace</span>
            </button>
          </div>
        </div>
        {contextMenu.isOpen && (
          <div
            className="context-menu absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg"
            style={{
              top: `${contextMenu.position.y}px`,
              left: `${contextMenu.position.x}px`,
              minWidth: '100px'
            }}
          >
            <button
              onClick={() => {
                handleOpenModal(contextMenu.workspace);
                setContextMenu({ ...contextMenu, isOpen: false });
              }}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Edit Workspace
            </button>
            <button
              onClick={() => {
                openDeleteModal(contextMenu.workspace);
                setContextMenu({ ...contextMenu, isOpen: false });
              }}
              className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
            >
              Delete Workspace
            </button>
          </div>
        )}
        <WorkspaceModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          onSave={handleSaveWorkspace}
          workspace={workspaceToEdit}
        />
        <ConfirmDeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          onConfirm={handleDeleteWorkspace}
          itemName={workspaceToDelete?.title}
          itemType="Workspace"
        />
      </div>
    );
  }

  return (
    <div className={`flex flex-col bg-white border-r border-gray-200 ${selectedWorkspace ? 'h-full' : 'h-3/4 mt-auto'}`}>
      <div className="flex items-center justify-between px-4 py-4 border-b border-gray-200">
        <h2 className="text-xl font-semibold text-gray-800">{project.name}</h2>
        <button
          className="p-1 rounded-full hover:bg-gray-100 focus:outline-none transition duration-200 ease-in-out"
          onClick={() => handleOpenModal(null)}
        >
          <PlusCircleIcon className="h-6 w-6 text-gray-600 hover:text-gray-800" />
        </button>
      </div>
      <div className="flex-1 overflow-y-auto">
        {workspaces.length === 0 ? (
          <div className="p-6 text-center h-full flex flex-col items-center justify-center">
            <p className="text-gray-600 mb-4">No workspaces yet.</p>
            <button
              onClick={() => handleOpenModal(null)}
              className="px-4 py-2 bg-gray-100 text-gray-800 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-opacity-50 transition duration-200 ease-in-out"
            >
              Create Your First Workspace
            </button>
          </div>
        ) : (
          <ul>
            {workspaces.map((workspace) => {
              const isSelected = selectedWorkspace && selectedWorkspace.id === workspace.id;
              return (
                <li key={workspace.id} className="relative">
                  <button
                    className={`text-left w-full px-4 py-3 transition duration-200 ease-in-out ${isSelected ? 'bg-blue-50 hover:bg-blue-100' : 'hover:bg-gray-50'
                      }`}
                    onClick={() => handleWorkspaceSelect(workspace)}
                  >
                    <p className={`font-medium ${isSelected ? 'text-blue-600' : 'text-gray-800'}`}>
                      {workspace.title}
                    </p>
                  </button>
                  <button
                    onClick={(e) => openContextMenu(e, workspace)}
                    className="absolute top-2 right-2 p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    <DotsVerticalIcon className="h-5 w-5" />
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      {contextMenu.isOpen && (
        <div
          className="context-menu absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg"
          style={{
            top: `${contextMenu.position.y}px`,
            left: `${contextMenu.position.x}px`,
            minWidth: '100px'
          }}
        >
          <button
            onClick={() => {
              handleOpenModal(contextMenu.workspace);
              setContextMenu({ ...contextMenu, isOpen: false });
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Edit Workspace
          </button>
          <button
            onClick={() => {
              openDeleteModal(contextMenu.workspace);
              setContextMenu({ ...contextMenu, isOpen: false });
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Delete Workspace
          </button>
        </div>
      )}
      <WorkspaceModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onSave={handleSaveWorkspace}
        workspace={workspaceToEdit}
      />
      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteWorkspace}
        itemName={workspaceToDelete?.title}
        itemType="Workspace"
      />
    </div>
  );
};

export default Workspaces;