import React from 'react';
import Modal from './Modal';
import Documents from './Documents';

const DocumentsModal = ({ isOpen, onClose, onAttach }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Attach Document">
      <Documents isModal={true} onAttach={onAttach} />
    </Modal>
  );
};

export default DocumentsModal;