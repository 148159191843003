import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import ProjectModal from './ProjectModal';
import { UserCircleIcon } from '@heroicons/react/solid';

const Header = ({ projects, onAddProject }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const searchRef = useRef(null);
  const userMenuRef = useRef(null);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const { projectId } = useParams();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
      if (userMenuRef.current && !userMenuRef.current.contains(event.target)) {
        setIsUserMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredProjects = projects
    .filter((project) => {
      const searchTermLower = searchTerm.toLowerCase();
      const nameLower = project.name.toLowerCase();
      const descriptionLower = project.description.toLowerCase();
      return nameLower.includes(searchTermLower) || descriptionLower.includes(searchTermLower);
    })
    .sort((a, b) => {
      const searchTermLower = searchTerm.toLowerCase();
      const descriptionMatchA = a.description.toLowerCase() === searchTermLower;
      const descriptionMatchB = b.description.toLowerCase() === searchTermLower;
      const nameMatchA = a.name.toLowerCase().includes(searchTermLower);
      const nameMatchB = b.name.toLowerCase().includes(searchTermLower);

      if (descriptionMatchA && !descriptionMatchB) return -1;
      if (!descriptionMatchA && descriptionMatchB) return 1;
      if (nameMatchA && !nameMatchB) return -1;
      if (!nameMatchA && nameMatchB) return 1;
      return 0;
    })
    .slice(0, 5);

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setShowSuggestions(true);
  };

  const handleSuggestionClick = (project) => {
    setShowSuggestions(false);
    setSearchTerm('');
    navigate(`/projects/${project.id}`);
  };

  const handleAddProject = () => {
    setIsModalOpen(true);
    setShowSuggestions(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalAddProject = async (name, description) => {
    try {
      const newProject = await onAddProject(name, description);
      navigate(`/projects/${newProject.id}`);
      setSearchTerm('');
    } catch (error) {
      console.error('Error adding project:', error);
    }
  };

  const handleDocumentsClick = () => {
    navigate('/documents');
    setIsUserMenuOpen(false);
    setSearchTerm('');
  };

  const handleSettingsClick = () => {
    navigate('/settings');
    setIsUserMenuOpen(false);
    setSearchTerm('');
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleUserMenu = () => {
    setIsUserMenuOpen(!isUserMenuOpen);
  };

  return (
    <header className="bg-white shadow w-full">
      <div className="w-full px-4 sm:px-6 lg:px-4">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="flex-shrink-0">
            <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-[#667eea] to-[#764ba2] font-montserrat">
              Celeria
            </h1>
          </Link>
          <div className="flex-grow flex justify-center px-4 relative" ref={searchRef}>
            <input
              type="text"
              placeholder="Search projects..."
              value={searchTerm}
              onChange={handleInputChange}
              className="w-full max-w-md px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {showSuggestions && (
              <ul className="absolute z-10 mt-10 w-full max-w-md bg-white border border-gray-300 rounded-md shadow-lg">
                {filteredProjects.map((project) => (
                  <li
                    key={project.id}
                    onClick={() => handleSuggestionClick(project)}
                    className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                  >
                    {project.name}
                  </li>
                ))}
                <li
                  onClick={handleAddProject}
                  className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                >
                  {'Add new project'}
                </li>
              </ul>
            )}
          </div>
          <div className="flex-shrink-0 relative" ref={userMenuRef}>
            <button
              onClick={toggleUserMenu}
              className="p-2 text-gray-500 rounded-full hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <UserCircleIcon className="h-6 w-6" />
            </button>
            {isUserMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                <button
                  onClick={handleDocumentsClick}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Documents
                </button>
                <button
                  onClick={handleSettingsClick}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Settings
                </button>
                <button
                  onClick={handleLogout}
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <ProjectModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onAddProject={handleModalAddProject}
        initialName={searchTerm}
      />
    </header>
  );
};

export default Header;