const Toaster = ({ message, onClose }) => {
  if (!message) return null;

  return (
    <div
      onClick={onClose}
      className="fixed bottom-4 right-4 z-50 bg-red-500 text-white px-4 py-2 rounded-md shadow-lg transition-opacity duration-300 cursor-pointer"
    >
      {message}
    </div>
  );
};

export default Toaster;