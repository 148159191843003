import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { PaperClipIcon } from '@heroicons/react/outline';
import Api from '../utils/api';

const TemplateMessageModal = ({ isOpen, onClose, message }) => {
  const [documentNames, setDocumentNames] = useState({});

  useEffect(() => {
    if (isOpen && message && message.template.documents.length > 0) {
      fetchDocumentNames();
    }
  }, [isOpen, message]);

  const fetchDocumentNames = async () => {
    const newDocumentNames = {};
    for (const doc of message.template.documents) {
      const docId = Object.values(doc)[0];
      try {
        const data = await Api.getDocument(docId);
        newDocumentNames[docId] = data.fileName;
      } catch (error) {
        console.error('Error fetching document name:', error);
        newDocumentNames[docId] = 'Unknown File';
      }
    }
    setDocumentNames(newDocumentNames);
  };

  const openDocument = async (documentId) => {
    try {
      const data = await Api.getDocument(documentId);
      const blob = new Blob([Uint8Array.from(atob(data.binaryData), c => c.charCodeAt(0))], { type: data.mimeType });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  if (!isOpen || !message) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-600 bg-opacity-50 flex items-center justify-center">
      <div className="relative bg-white rounded-lg shadow-xl max-w-2xl w-full m-4">
        <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="sm:flex sm:items-start">
            <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">{message.template.name}</h3>
              <div className="mt-4 space-y-6">
                {message.template.variables && message.template.variables.length > 0 && (
                  <div className="max-h-60 overflow-y-auto">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Variables</label>
                    {message.template.variables.map((variable, index) => (
                      <div key={index} className="flex items-center space-x-2 mb-2">
                        <div className="w-1/2 px-3 py-2 bg-gray-100 rounded-md">
                          {Object.keys(variable)[0]}
                        </div>
                        <div className="w-1/2 px-3 py-2 bg-gray-100 rounded-md">
                          {Object.values(variable)[0]}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {message.template.documents && message.template.documents.length > 0 && (
                  <div className="max-h-60 overflow-y-auto">
                    <label className="block text-sm font-medium text-gray-700 mb-2">Documents</label>
                    {message.template.documents.map((document, index) => (
                      <div key={index} className="flex items-center space-x-2 mb-2">
                        <div className="w-1/2 px-3 py-2 bg-gray-100 rounded-md">
                          {Object.keys(document)[0]}
                        </div>
                        <div className="w-1/2 flex items-center bg-gray-100 px-3 py-2 rounded-md overflow-hidden">
                          <button
                            onClick={() => openDocument(Object.values(document)[0])}
                            className="flex-1 text-left text-blue-600 hover:text-blue-800 focus:outline-none truncate"
                          >
                            <PaperClipIcon className="h-5 w-5 inline-block mr-1" />
                            <span className="text-sm truncate">{documentNames[Object.values(document)[0]] || 'Loading...'}</span>
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-2">Message Content</label>
                  <div className="w-full px-3 py-2 bg-gray-100 rounded-md prose max-w-none">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>
                      {message.content || ''}
                    </ReactMarkdown>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={onClose}
            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default TemplateMessageModal;