import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { PlusIcon, DotsVerticalIcon } from '@heroicons/react/outline';
import Workspaces from './Workspaces';
import Workspace from './Workspace';
import Api from '../utils/api';
import ProjectModal from './ProjectModal';
import ConfirmDeleteModal from './ConfirmDeleteModal';

const ProjectView = ({ projects, setProjects, templates, saveTemplate, deleteTemplate, inferenceProviders, onAddProject }) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [isProjectModalOpen, setIsProjectModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [projectToDelete, setProjectToDelete] = useState(null);
  const [projectToEdit, setProjectToEdit] = useState(null);
  const { projectId, workspaceId } = useParams();
  const [contextMenu, setContextMenu] = useState({ isOpen: false, position: { x: 0, y: 0 }, project: null });
  const navigate = useNavigate();

  useEffect(() => {
    if (projectId && projects.length > 0) {
      const project = projects.find(a => a.id === parseInt(projectId));
      setSelectedProject(project || null);
    } else {
      setSelectedProject(null);
    }
  }, [projectId, projects]);

  useEffect(() => {
    if (selectedProject && workspaceId) {
      fetchWorkspace();
    } else {
      setSelectedWorkspace(null);
    }
  }, [selectedProject, workspaceId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (contextMenu.isOpen && !event.target.closest('.context-menu')) {
        setContextMenu({ ...contextMenu, isOpen: false });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [contextMenu]);

  const fetchWorkspace = async () => {
    try {
      const workspace = await Api.getWorkspace(selectedProject.id, workspaceId);
      setSelectedWorkspace(workspace);
    } catch (error) {
      console.error('Error fetching workspace:', error);
      setSelectedWorkspace(null);
    }
  };

  const handleDeleteProject = async () => {
    if (projectToDelete) {
      try {
        await Api.deleteProject(projectToDelete.id);
        setProjects(prevProjects => prevProjects.filter(project => project.id !== projectToDelete.id));
        navigate('/');
      } catch (error) {
        console.error('Error deleting project:', error);
      }
    }
    setIsDeleteModalOpen(false);
    setProjectToDelete(null);
  };

  const openDeleteModal = (project) => {
    setProjectToDelete(project);
    setIsDeleteModalOpen(true);
  };

  const handleOpenProjectModal = (project = null) => {
    setProjectToEdit(project);
    setIsProjectModalOpen(true);
  };

  const handleCloseProjectModal = () => {
    setIsProjectModalOpen(false);
    setProjectToEdit(null);
  };

  const handleSaveProject = async (name, description) => {
    try {
      if (projectToEdit) {
        const updatedProject = await Api.updateProject(projectToEdit.id, { name, description });
        setProjects(prevProjects =>
          prevProjects.map(p => p.id === updatedProject.id ? updatedProject : p)
        );
      } else {
        const newProject = await onAddProject(name, description);
        navigate(`/projects/${newProject.id}`);
      }
    } catch (error) {
      console.error('Error saving project:', error);
    }
  };

  const openContextMenu = (event, project) => {
    event.preventDefault();
    const rect = event.currentTarget.getBoundingClientRect();
    setContextMenu({
      isOpen: true,
      position: {
        x: rect.right - 120,
        y: rect.bottom
      },
      project: project
    });
  };

  const renderProjectList = () => (
    <div className="max-w-4xl mx-auto p-8 h-[90vh] overflow-hidden flex flex-col">
      <div className="text-center mb-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-2">Projects</h1>
      </div>
      <div className="overflow-y-auto flex-grow">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 px-4 pb-4">
          {projects.map((project) => (
            <div key={project.id} className="relative">
              <Link
                to={`/projects/${project.id}`}
                className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition duration-200 ease-in-out text-left w-full border border-gray-200 hover:border-blue-500 flex flex-col h-36"
              >
                <h3 className="text-xl font-semibold text-gray-800 mb-2">{project.name}</h3>
                <div className="relative flex-grow overflow-hidden">
                  <p className="text-gray-500 absolute inset-0 transition-all duration-300 ease-in-out hover:overflow-auto">
                    {project.description || 'No description available'}
                  </p>
                </div>
              </Link>
              <button
                onClick={(e) => openContextMenu(e, project)}
                className="absolute top-2 right-2 p-2 text-gray-500 hover:text-gray-700 focus:outline-none"
              >
                <DotsVerticalIcon className="h-5 w-5" />
              </button>
            </div>
          ))}
          <button
            onClick={() => handleOpenProjectModal()}
            className="bg-gray-50 p-6 rounded-lg shadow-md hover:shadow-lg transition duration-200 ease-in-out text-left w-full border border-gray-200 hover:border-blue-500 flex items-center justify-center h-36"
          >
            <PlusIcon className="h-6 w-6 text-gray-400 mr-2" />
            <span className="text-lg font-semibold text-gray-600">New Project</span>
          </button>
        </div>
      </div>
      {contextMenu.isOpen && (
        <div
          className="context-menu absolute z-10 bg-white border border-gray-300 rounded-md shadow-lg"
          style={{
            top: `${contextMenu.position.y}px`,
            left: `${contextMenu.position.x}px`,
            minWidth: '100px'
          }}
        >
          <button
            onClick={() => {
              handleOpenProjectModal(contextMenu.project);
              setContextMenu({ ...contextMenu, isOpen: false });
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Edit Project
          </button>
          <button
            onClick={() => {
              openDeleteModal(contextMenu.project);
              setContextMenu({ ...contextMenu, isOpen: false });
            }}
            className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
          >
            Delete Project
          </button>
        </div>
      )}
      <ProjectModal
        isOpen={isProjectModalOpen}
        onClose={handleCloseProjectModal}
        onSave={handleSaveProject}
        project={projectToEdit}
      />
      <ConfirmDeleteModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteProject}
        itemName={projectToDelete?.name}
        itemType="Project"
      />
    </div>
  );

  if (!selectedProject) {
    return renderProjectList();
  }

  return (
    <div className="flex h-full">
      {selectedWorkspace ? (
        <>
          <div className="w-64 flex-none border-r border-gray-300">
            <Workspaces
              project={selectedProject}
              selectedWorkspace={selectedWorkspace}
            />
          </div>
          <div className="flex-1">
            <Workspace
              key={selectedWorkspace.id}
              workspace={selectedWorkspace}
              project={selectedProject}
              templates={templates}
              saveTemplate={saveTemplate}
              deleteTemplate={deleteTemplate}
              inferenceProviders={inferenceProviders}
            />
          </div>
        </>
      ) : (
        <div className="flex-1 flex items-center justify-center">
          <Workspaces
            project={selectedProject}
            selectedWorkspace={selectedWorkspace}
            centered={true}
          />
        </div>
      )}
    </div>
  );
};

export default ProjectView;