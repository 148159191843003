import React, { useState, useEffect, useRef } from 'react';
import { PlusCircleIcon, ChatAlt2Icon } from '@heroicons/react/outline';
import Conversation from './Conversation';
import Api from '../utils/api';

const Workspace = ({ workspace, project, templates, saveTemplate, deleteTemplate, inferenceProviders }) => {
  const [conversations, setConversations] = useState([]);
  const [maximizedConversationId, setMaximizedConversationId] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const data = await Api.getConversations(project.id, workspace.id);
        setConversations(data);
      } catch (error) {
        console.error('Error fetching conversations:', error);
      }
    };

    fetchConversations();
  }, [project.id, workspace.id]);

  useEffect(() => {
    if (maximizedConversationId) {
      const conversationElement = document.getElementById(`conversation-${maximizedConversationId}`);
      if (conversationElement) {
        conversationElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [maximizedConversationId]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        menuButtonRef.current &&
        !menuButtonRef.current.contains(event.target)
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNewConversation = async () => {
    try {
      const newConversation = await Api.addConversation(project.id, workspace.id);
      setConversations([newConversation, ...conversations]);
      setMaximizedConversationId(newConversation.id);
    } catch (error) {
      console.error('Error creating new conversation:', error);
    }
  };

  const handleMaximizeConversation = (conversationId) => {
    setMaximizedConversationId(conversationId);
  };

  const handleMinimizeConversation = () => {
    setMaximizedConversationId(null);
  };

  const handleDeleteConversation = async (conversationId) => {
    try {
      const isDeleted = await Api.deleteConversation(project.id, workspace.id, conversationId);
      if (isDeleted) {
        setConversations(conversations.filter((conversation) => conversation.id !== conversationId));
        if (maximizedConversationId === conversationId) {
          setMaximizedConversationId(null);
        }
      }
    } catch (error) {
      console.error('Error deleting conversation:', error);
    }
  };

  const handleCloneConversation = async (conversationId) => {
    try {
      const originalConversation = await Api.getConversation(project.id, workspace.id, conversationId);
      
      // Modify the messages to only include documentIds for attachments
      const modifiedMessages = originalConversation.messages.map(message => ({
        ...message,
        attachments: message.attachments ? message.attachments.map(att => 
          typeof att === 'string' ? att : att.id
        ) : undefined
      }));
  
      const clonedConversation = await Api.addConversation(project.id, workspace.id, modifiedMessages);
      setConversations([clonedConversation, ...conversations]);
      setMaximizedConversationId(clonedConversation.id);
    } catch (error) {
      console.error('Error cloning conversation:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Sort conversations by ID in descending order
  const sortedConversations = conversations.sort((a, b) => b.id - a.id);

  return (
    <div className="flex flex-col h-full">
      <div className="flex items-center justify-between px-4 py-4">
        <h2 className="text-xl font-medium truncate">{workspace.title}</h2>
        <div className="relative">
          <button
            ref={menuButtonRef}
            className="p-1 rounded-full hover:bg-gray-100 focus:outline-none"
            onClick={toggleMenu}
          >
            <PlusCircleIcon className="h-6 w-6 text-gray-700" />
          </button>
          {isMenuOpen && (
            <div
              ref={menuRef}
              className="absolute right-0 mt-1 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10"
              style={{ top: '100%' }}
            >
              <button
                className="flex items-center w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                onClick={() => {
                  handleNewConversation();
                  setIsMenuOpen(false);
                }}
              >
                <ChatAlt2Icon className="h-5 w-5 mr-3 text-gray-500" />
                New Conversation
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="flex-1 overflow-y-auto relative">
        {sortedConversations.length === 0 ? (
          <div className="flex flex-col items-center justify-center h-full text-gray-500">
            <ChatAlt2Icon className="w-16 h-16 mb-4" />
            <h3 className="text-xl font-semibold mb-2">No conversations yet</h3>
            <p className="text-center mb-4">Start a new conversation to begin exploring this workspace.</p>
            <button
              onClick={handleNewConversation}
              className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-200 ease-in-out"
            >
              Start New Conversation
            </button>
          </div>
        ) : (
          sortedConversations.map((conversation) => (
            <div
              key={conversation.id || 'new'}
              className={`${maximizedConversationId !== null && conversation.id !== maximizedConversationId ? 'hidden' : ''
                }`}
            >
              <Conversation
                conversationId={conversation.id}
                projectId={project.id}
                workspaceId={workspace.id}
                isMaximized={conversation.id === maximizedConversationId}
                onMaximize={handleMaximizeConversation}
                onMinimize={handleMinimizeConversation}
                onDeleteConversation={handleDeleteConversation}
                onCloneConversation={handleCloneConversation}
                templates={templates}
                saveTemplate={saveTemplate}
                deleteTemplate={deleteTemplate}
                inferenceProviders={inferenceProviders}
              />
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default Workspace;