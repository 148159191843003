import React, { useState, useEffect, useRef } from 'react';
import { PlusIcon, TrashIcon, PaperClipIcon, XIcon } from '@heroicons/react/outline';
import DocumentsModal from './DocumentsModal';
import Api from '../utils/api';

const defaultState = {
    selectedTemplate: '',
    templateName: '',
    variables: [],
    documents: [],
    prompt: '',
    outputFormat: '',
};

const HighlightedTextarea = ({ value, onChange, onKeyDown, variables, ...props }) => {
    const [highlightedValue, setHighlightedValue] = useState('');
    const textareaRef = useRef(null);
    const displayRef = useRef(null);

    useEffect(() => {
        highlightText();
    }, [value, variables]);

    const highlightText = () => {
        let highlightedText = value;
        variables.forEach(variable => {
            const regex = new RegExp(`{${variable.name}}`, 'g');
            highlightedText = highlightedText.replace(regex, `<span class="text-blue-500">{${variable.name}}</span>`);
        });
        setHighlightedValue(highlightedText);
    };

    const handleInput = (e) => {
        const newValue = e.target.value;
        onChange({ target: { value: newValue } });
    };

    const handleScroll = () => {
        if (displayRef.current) {
            displayRef.current.scrollTop = textareaRef.current.scrollTop;
        }
    };

    const handleClick = (e) => {
        const textareaRect = textareaRef.current.getBoundingClientRect();
        const x = e.clientX - textareaRect.left;
        const y = e.clientY - textareaRect.top;

        // Create a temporary element to measure text
        const temp = document.createElement('span');
        temp.style.font = window.getComputedStyle(textareaRef.current).font;
        temp.style.whiteSpace = 'pre-wrap';
        temp.style.position = 'absolute';
        temp.style.visibility = 'hidden';
        document.body.appendChild(temp);

        let closestPosition = 0;
        let closestDistance = Infinity;

        for (let i = 0; i <= value.length; i++) {
            temp.textContent = value.slice(0, i);
            const rect = temp.getBoundingClientRect();
            const distance = Math.sqrt(Math.pow(rect.width - x, 2) + Math.pow(rect.height - y, 2));

            if (distance < closestDistance) {
                closestDistance = distance;
                closestPosition = i;
            }
        }

        document.body.removeChild(temp);
        textareaRef.current.setSelectionRange(closestPosition, closestPosition);
    };

    return (
        <div className="relative" style={{ minHeight: '100px', maxHeight: '300px' }}>
            <textarea
                ref={textareaRef}
                value={value}
                onChange={handleInput}
                onKeyDown={onKeyDown}
                onScroll={handleScroll}
                className="absolute w-full h-full px-3 py-2 text-transparent caret-black bg-transparent border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 resize-none"
                style={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word'
                }}
                {...props}
            />
            <div
                ref={displayRef}
                onClick={handleClick}
                className="w-full h-full px-3 py-2 border border-transparent rounded-md pointer-events-none whitespace-pre-wrap break-words overflow-auto"
                style={{ minHeight: '100px', maxHeight: '300px' }}
                dangerouslySetInnerHTML={{ __html: highlightedValue || '<br>' }}
            />
        </div>
    );
};

const TemplateModal = ({ isOpen, onClose, onSave, handleRun, templates, selectedTemplate, onDeleteTemplate }) => {
    const [state, setState] = useState(defaultState);
    const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
    const [currentDocumentIndex, setCurrentDocumentIndex] = useState(null);
    const [showAutocomplete, setShowAutocomplete] = useState(false);
    const [autocompleteIndex, setAutocompleteIndex] = useState(0);
    const [autocompleteFilter, setAutocompleteFilter] = useState('');
    const autocompleteRef = useRef(null);

    useEffect(() => {
        if (isOpen) {
            if (selectedTemplate) {
                const template = templates.find(t => t.name === selectedTemplate);
                if (template) {
                    setState({
                        ...defaultState,
                        ...template,
                        selectedTemplate: template.name,
                        templateName: template.name,
                    });
                } else {
                    setState(defaultState);
                }
            } else {
                setState(defaultState);
            }
        }
    }, [isOpen, selectedTemplate, templates]);

    const handleSelectTemplate = (templateName) => {
        if (templateName) {
            const selectedTemplate = templates.find(template => template.name === templateName);
            if (selectedTemplate) {
                setState({
                    ...defaultState,
                    ...selectedTemplate,
                    selectedTemplate: templateName,
                    templateName: selectedTemplate.name,
                });
            }
        } else {
            setState(defaultState);
        }
    };

    const handleSave = () => {
        if (!isTemplateValid()) return;

        const newTemplate = {
            id: state.id,
            name: state.templateName,
            variables: state.variables.filter(v => v.name !== ''),
            documents: state.documents.filter(d => d.name !== ''),
            prompt: state.prompt,
            outputFormat: state.outputFormat,
        };

        onSave(newTemplate);
        setState({ ...defaultState, templateName: newTemplate.name, selectedTemplate: newTemplate.name });

        console.log('Template saved:', newTemplate);
    };

    const handleDelete = () => {
        if (state.selectedTemplate) {
            onDeleteTemplate(state.id);
            setState(defaultState);
        }
    };

    const isTemplateValid = () => {
        return (
            state.templateName && state.templateName.trim() !== '' &&
            state.variables.every(v => v && v.name && v.name.trim() !== '') &&
            state.documents.every(d => d && d.name && d.name.trim() !== '') &&
            state.prompt && state.prompt.trim() !== ''
        );
    };

    const addVariable = () => {
        setState(prevState => ({
            ...prevState,
            variables: [...prevState.variables, { name: '', value: '' }]
        }));
    };

    const removeVariable = (index) => {
        setState(prevState => ({
            ...prevState,
            variables: prevState.variables.filter((_, i) => i !== index)
        }));
    };

    const addDocument = () => {
        setState(prevState => ({
            ...prevState,
            documents: [...prevState.documents, { name: '', id: '', fileName: '' }]
        }));
    };

    const removeDocument = (index) => {
        setState(prevState => ({
            ...prevState,
            documents: prevState.documents.filter((_, i) => i !== index)
        }));
    };

    const removeUploadedDocument = (index) => {
        setState(prevState => {
            const newDocuments = [...prevState.documents];
            newDocuments[index] = { ...newDocuments[index], id: '', fileName: '' };
            return { ...prevState, documents: newDocuments };
        });
    };

    const handleOpenDocumentsModal = (index) => {
        setIsDocumentsModalOpen(true);
        setCurrentDocumentIndex(index);
    };

    const handleCloseDocumentsModal = () => {
        setIsDocumentsModalOpen(false);
        setCurrentDocumentIndex(null);
    };

    const handleAttachDocument = (document) => {
        if (currentDocumentIndex !== null) {
            const newDocuments = [...state.documents];
            newDocuments[currentDocumentIndex] = {
                name: newDocuments[currentDocumentIndex].name,
                id: document.documentId,
                fileName: document.fileName
            };
            setState(prevState => ({ ...prevState, documents: newDocuments }));
        }
        setIsDocumentsModalOpen(false);
        setCurrentDocumentIndex(null);
    };

    const openDocument = async (documentId) => {
        try {
            const data = await Api.getDocument(documentId);
            const blob = new Blob([Uint8Array.from(atob(data.binaryData), c => c.charCodeAt(0))], { type: data.mimeType });
            const url = URL.createObjectURL(blob);
            window.open(url, '_blank');
        } catch (error) {
            console.error('Error fetching document:', error);
        }
    };

    const handleKeyDown = (e) => {
        if (showAutocomplete) {
            const filteredVariables = state.variables.filter(v =>
                v.name.toLowerCase().includes(autocompleteFilter.toLowerCase())
            );
            switch (e.key) {
                case 'ArrowDown':
                    e.preventDefault();
                    setAutocompleteIndex((prevIndex) =>
                        (prevIndex + 1) % filteredVariables.length
                    );
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    setAutocompleteIndex((prevIndex) =>
                        (prevIndex - 1 + filteredVariables.length) % filteredVariables.length
                    );
                    break;
                case 'Enter':
                    if (filteredVariables.length > 0) {
                        e.preventDefault();
                        insertVariable(filteredVariables[autocompleteIndex].name);
                    }
                    break;
                case 'Escape':
                    setShowAutocomplete(false);
                    setAutocompleteFilter('');
                    break;
                default:
                    break;
            }
        }
    };

    const handlePromptChange = (e) => {
        const newPrompt = e.target.value;
        const newCursorPosition = e.target.selectionStart;

        setState(prevState => ({ ...prevState, prompt: newPrompt }));

        console.log("Cursor position:", newCursorPosition);

        const lastOpenBraceIndex = newPrompt.lastIndexOf('{', newCursorPosition);
        if (lastOpenBraceIndex !== -1) {
            const textAfterBrace = newPrompt.slice(lastOpenBraceIndex + 1, newCursorPosition);
            setAutocompleteFilter(textAfterBrace);
            setShowAutocomplete(true);
            setAutocompleteIndex(0);
        } else {
            setShowAutocomplete(false);
            setAutocompleteFilter('');
        }
    };

    const insertVariable = (variableName) => {
        const textarea = document.getElementById('prompt');
        const cursorPosition = textarea.selectionStart;

        console.log("Current prompt:", state.prompt);
        console.log("Cursor position:", cursorPosition);

        const lastOpenBraceIndex = state.prompt.lastIndexOf('{', cursorPosition);
        console.log("Last open brace index:", lastOpenBraceIndex);

        let newPrompt;
        if (lastOpenBraceIndex !== -1) {
            // Find the end of the partial variable name
            const endOfPartialVariable = state.prompt.indexOf('}', lastOpenBraceIndex);
            const replaceEndIndex = endOfPartialVariable !== -1 && endOfPartialVariable < cursorPosition
                ? endOfPartialVariable + 1
                : cursorPosition;

            // Replace the partial variable name with the full variable
            newPrompt = state.prompt.slice(0, lastOpenBraceIndex) + '{' + variableName + '}' +
                state.prompt.slice(replaceEndIndex);
        } else {
            // If no open brace found, just insert the variable at the cursor position
            newPrompt = state.prompt.slice(0, cursorPosition) + '{' + variableName + '}' +
                state.prompt.slice(cursorPosition);
        }

        console.log("New prompt:", newPrompt);

        setState(prevState => ({ ...prevState, prompt: newPrompt }));
        setShowAutocomplete(false);
        setAutocompleteFilter('');
        setAutocompleteIndex(0);

        // Set focus back to the textarea and update cursor position
        setTimeout(() => {
            textarea.focus();
            const newCursorPosition = lastOpenBraceIndex !== -1
                ? lastOpenBraceIndex + variableName.length + 2
                : cursorPosition + variableName.length + 2;
            textarea.setSelectionRange(newCursorPosition, newCursorPosition);
        }, 0);
    };

    useEffect(() => {
        if (showAutocomplete && autocompleteRef.current) {
            const activeItem = autocompleteRef.current.children[autocompleteIndex];
            if (activeItem) {
                activeItem.scrollIntoView({ block: 'nearest' });
            }
        }
    }, [autocompleteIndex, showAutocomplete]);

    const filteredVariables = state.variables.filter(v =>
        v.name.toLowerCase().includes(autocompleteFilter.toLowerCase())
    );

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 overflow-y-auto bg-gray-600 bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white rounded-lg shadow-xl max-w-2xl w-full m-4">
                <div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">Create Template</h3>
                            <div className="mt-4 space-y-6">
                                <select
                                    value={state.selectedTemplate}
                                    onChange={(e) => handleSelectTemplate(e.target.value)}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                >
                                    <option value="">New Template</option>
                                    {templates.map((template, index) => (
                                        <option key={index} value={template.name}>{template.name}</option>
                                    ))}
                                </select>
                                <input
                                    type="text"
                                    placeholder="Template Name"
                                    value={state.templateName}
                                    onChange={(e) => setState(prevState => ({ ...prevState, templateName: e.target.value }))}
                                    className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                />
                                <div className="max-h-60 overflow-y-auto">
                                    <label className="block text-sm font-medium text-gray-700 mb-2">Variables</label>
                                    {state.variables.map((variable, index) => (
                                        <div key={index} className="flex items-center space-x-2 mb-2">
                                            <input
                                                type="text"
                                                placeholder="Variable name"
                                                value={variable.name}
                                                onChange={(e) => {
                                                    const newVariables = [...state.variables];
                                                    newVariables[index].name = e.target.value;
                                                    setState(prevState => ({ ...prevState, variables: newVariables }));
                                                }}
                                                className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                            />
                                            <input
                                                type="text"
                                                placeholder="Value"
                                                value={variable.value}
                                                onChange={(e) => {
                                                    const newVariables = [...state.variables];
                                                    newVariables[index].value = e.target.value;
                                                    setState(prevState => ({ ...prevState, variables: newVariables }));
                                                }}
                                                className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                            />
                                            <button
                                                type="button"
                                                onClick={() => removeVariable(index)}
                                                className="p-2 rounded-md text-gray-400 hover:text-gray-600 focus:outline-none"
                                            >
                                                <TrashIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={addVariable}
                                        className="mt-1 px-3 py-2 border border-gray-300 rounded-md bg-green-50 text-green-700 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                    >
                                        <PlusIcon className="h-5 w-5 inline-block mr-1" /> Add Variable
                                    </button>
                                </div>
                                <div className="max-h-60 overflow-y-auto">
                                    <label className="block text-sm font-medium text-gray-700 mb-2">Documents</label>
                                    {state.documents.map((document, index) => (
                                        <div key={index} className="flex items-center space-x-2 mb-2">
                                            <input
                                                type="text"
                                                placeholder="Document name"
                                                value={document.name}
                                                onChange={(e) => {
                                                    const newDocuments = [...state.documents];
                                                    newDocuments[index].name = e.target.value;
                                                    setState(prevState => ({ ...prevState, documents: newDocuments }));
                                                }}
                                                className="w-1/2 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                            />
                                            {document.id ? (
                                                <div className="w-1/2 flex items-center bg-gray-100 px-3 py-2 rounded-md overflow-hidden">
                                                    <button
                                                        onClick={() => openDocument(document.id)}
                                                        className="flex-1 text-left text-blue-600 hover:text-blue-800 focus:outline-none truncate"
                                                    >
                                                        <PaperClipIcon className="h-5 w-5 inline-block mr-1" />
                                                        <span className="text-sm truncate">{document.fileName}</span>
                                                    </button>
                                                    <button
                                                        onClick={() => removeUploadedDocument(index)}
                                                        className="ml-2 text-gray-400 hover:text-gray-600 focus:outline-none"
                                                    >
                                                        <XIcon className="h-5 w-5" />
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="w-1/2">
                                                    <button
                                                        onClick={() => handleOpenDocumentsModal(index)}
                                                        className="w-full inline-flex justify-center items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                                                    >
                                                        Select Document
                                                    </button>
                                                </div>
                                            )}
                                            <button
                                                onClick={() => removeDocument(index)}
                                                className="p-2 rounded-md text-gray-400 hover:text-gray-600 focus:outline-none"
                                            >
                                                <TrashIcon className="h-5 w-5" />
                                            </button>
                                        </div>
                                    ))}
                                    <button
                                        type="button"
                                        onClick={addDocument}
                                        className="mt-1 px-3 py-2 border border-gray-300 rounded-md bg-green-50 text-green-700 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                    >
                                        <PlusIcon className="h-5 w-5 inline-block mr-1" /> Add Document
                                    </button>
                                </div>
                                <div className="relative">
                                    <label htmlFor="prompt" className="block text-sm font-medium text-gray-700 mb-2">Prompt</label>
                                    <HighlightedTextarea
                                        id="prompt"
                                        value={state.prompt}
                                        onChange={handlePromptChange}
                                        onKeyDown={handleKeyDown}
                                        variables={state.variables}
                                        placeholder="Enter prompt template..."
                                    />
                                    {showAutocomplete && filteredVariables.length > 0 && (
                                        <div
                                            ref={autocompleteRef}
                                            className="absolute z-20 mt-1 w-full max-h-60 overflow-auto bg-white border border-gray-300 rounded-md shadow-lg"
                                        >
                                            {filteredVariables.map((variable, index) => (
                                                <div
                                                    key={index}
                                                    className={`px-4 py-2 cursor-pointer ${index === autocompleteIndex ? 'bg-blue-100' : 'hover:bg-gray-100'
                                                        }`}
                                                    onClick={() => insertVariable(variable.name)}
                                                >
                                                    {variable.name}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <label htmlFor="outputFormat" className="block text-sm font-medium text-gray-700 mb-2">Output Format (Optional)</label>
                                    <textarea
                                        id="outputFormat"
                                        rows="2"
                                        value={state.outputFormat}
                                        onChange={(e) => setState(prevState => ({ ...prevState, outputFormat: e.target.value }))}
                                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter output format (optional)..."
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button
                        type="button"
                        onClick={handleRun}
                        disabled={!isTemplateValid()}
                        className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm ${!isTemplateValid() ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        Run
                    </button>
                    <button
                        type="button"
                        onClick={handleSave}
                        disabled={!isTemplateValid()}
                        className={`mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm ${!isTemplateValid() ? 'opacity-50 cursor-not-allowed' : ''}`}
                    >
                        Save
                    </button>
                    {state.selectedTemplate && (
                        <button
                            type="button"
                            onClick={handleDelete}
                            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-red-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                            Delete
                        </button>
                    )}
                    <button
                        type="button"
                        onClick={onClose}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <DocumentsModal
                isOpen={isDocumentsModalOpen}
                onClose={handleCloseDocumentsModal}
                onAttach={handleAttachDocument}
            />
        </div>
    );
};

export default TemplateModal;