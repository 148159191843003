// useTemplates.ts
import { useState, useEffect, useCallback } from 'react';
import Api from '../utils/api';

const useTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState(null);

  const fetchTemplates = useCallback(async () => {
    try {
      const fetchedTemplates = await Api.getTemplates();
      setTemplates(fetchedTemplates);
      setError(null);
    } catch (err) {
      console.error('Error fetching templates:', err);
      setError('Failed to fetch templates');
    }
  }, []);

  useEffect(() => {
    fetchTemplates();
  }, [fetchTemplates]);

  const saveTemplate = useCallback(async (newTemplate) => {
    try {
      if (newTemplate.id) {
        await Api.updateTemplate(newTemplate.id, newTemplate);
      } else {
        await Api.createTemplate(newTemplate);
      }
      await fetchTemplates();
      setError(null);
    } catch (err) {
      console.error('Error saving template:', err);
      setError('Failed to save template');
    }
  }, [fetchTemplates]);

  const deleteTemplate = useCallback(async (templateId) => {
    try {
      await Api.deleteTemplate(templateId);
      await fetchTemplates();
      setError(null);
    } catch (err) {
      console.error('Error deleting template:', err);
      setError('Failed to delete template');
    }
  }, [fetchTemplates]);

  return { templates, saveTemplate, deleteTemplate, fetchTemplates, error };
};

export default useTemplates;