// src/utils/api.js
class Api {
  static BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/api/v1';

  static async request(endpoint, method = 'GET', body = null) {
    const token = localStorage.getItem('jwt');
    const headers = {
      'Content-Type': 'application/json',
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const config = {
      method,
      headers,
    };

    if (body) {
      config.body = JSON.stringify(body);
    }

    try {
      const response = await fetch(`${this.BASE_URL}${endpoint}`, config);
      if (response.status === 401) {
        localStorage.removeItem('jwt');
        window.dispatchEvent(new CustomEvent('unauthorized'));
        throw new Error('Unauthorized');
      }
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      // Check if the response has content before parsing as JSON
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        return await response.json();
      } else {
        // Return an empty object for successful requests with no content
        return {};
      }
    } catch (error) {
      console.error('API request failed:', error);
      throw error;
    }
  }

  static get(endpoint) {
    return this.request(endpoint);
  }

  static post(endpoint, body) {
    return this.request(endpoint, 'POST', body);
  }

  static put(endpoint, body) {
    return this.request(endpoint, 'PUT', body);
  }

  static patch(endpoint, body) {
    return this.request(endpoint, 'PATCH', body);
  }

  static delete(endpoint) {
    return this.request(endpoint, 'DELETE');
  }

  // Add specific API methods here
  static async login(username, password) {
    return this.post('/login', { username, password });
  }

  static async verifyToken() {
    return this.get('/verify-token');
  }

  static async getInferenceProviders() {
    return this.get(`/users/inference-providers`);
  }

  static async getProjects() {
    return this.get('/projects');
  }

  static async addProject(name, description) {
    return this.post('/projects', { name, description });
  }

  static async getProject(projectId) {
    return this.get(`/projects/${projectId}`);
  }

  static async deleteProject(projectId) {
    return this.delete(`/projects/${projectId}`);
  }

  static async updateProject(projectId, updateData) {
    return this.patch(`/projects/${projectId}`, updateData);
  }

  static async getWorkspaces(projectId) {
    return this.get(`/projects/${projectId}/workspaces`);
  }

  static async addWorkspace(projectId, title) {
    return this.post(`/projects/${projectId}/workspaces`, { title });
  }

  static async getWorkspace(projectId, workspaceId) {
    return this.get(`/projects/${projectId}/workspaces/${workspaceId}`);
  }

  static async deleteWorkspace(projectId, workspaceId) {
    return this.delete(`/projects/${projectId}/workspaces/${workspaceId}`);
  }

  static async updateWorkspace(projectId, workspaceId, updateData) {
    return this.patch(`/projects/${projectId}/workspaces/${workspaceId}`, updateData);
  }
  
  static async patchConversation(projectId, workspaceId, conversationId, updateData) {
    return this.patch(`/projects/${projectId}/workspaces/${workspaceId}/conversations/${conversationId}`, updateData);
  }

  static async getConversations(projectId, workspaceId) {
    return this.get(`/projects/${projectId}/workspaces/${workspaceId}/conversations`);
  }

  static async addConversation(projectId, workspaceId, messages = []) {
    return this.post(`/projects/${projectId}/workspaces/${workspaceId}/conversations`, { messages });
  }

  static async getConversation(projectId, workspaceId, conversationId) {
    return this.get(`/projects/${projectId}/workspaces/${workspaceId}/conversations/${conversationId}`);
  }

  static async sendMessageWithStream(projectId, workspaceId, conversationId, message) {
    const token = localStorage.getItem('jwt');
    const url = conversationId
      ? `${this.BASE_URL}/projects/${projectId}/workspaces/${workspaceId}/conversations/${conversationId}`
      : `${this.BASE_URL}/projects/${projectId}/workspaces/${workspaceId}/conversations`;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({ messages: [message] }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response;
  }

  static async deleteConversation(projectId, workspaceId, conversationId) {
    const response = await fetch(`${this.BASE_URL}/projects/${projectId}/workspaces/${workspaceId}/conversations/${conversationId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('jwt')}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Return true if the deletion was successful
    return true;
  }

  static async uploadDocument(file) {
    const formData = new FormData();
    formData.append('file', file);

    const token = localStorage.getItem('jwt');
    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    const response = await fetch(`${this.BASE_URL}/documents`, {
      method: 'POST',
      headers,
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return response.json();
  }

  static async getDocument(documentId) {
    return this.get(`/documents/${documentId}`);
  }

  static async getDocuments() {
    return this.get(`/documents`);
  }

  static async getTemplates() {
    return this.get('/templates');
  }
  
  static async createTemplate(templateData) {
    return this.post('/templates', templateData);
  }
  
  static async updateTemplate(templateId, templateData) {
    return this.request(`/templates/${templateId}`, 'PATCH', templateData);
  }
  
  static async deleteTemplate(templateId) {
    return this.delete(`/templates/${templateId}`);
  }

   static async listAllProviders() {
    return this.get('/inference-providers');
  }

  static async addApiKey(providerId, nickname, key) {
    return this.post(`/inference-providers/${providerId}/api-keys`, { nickname, key });
  }

  static async deleteApiKey(providerId, apiKeyId) {
    return this.delete(`/inference-providers/${providerId}/api-keys/${apiKeyId}`);
  }
}

export default Api;