import React, { useState, useEffect } from 'react';
import Api from '../utils/api';
import { PlusCircleIcon, TrashIcon } from '@heroicons/react/outline';
import Toaster from './Toaster';
import Modal from './Modal';

const AddApiKeyModal = ({ isOpen, onClose, onAdd }) => {
    const [nickname, setNickname] = useState('');
    const [key, setKey] = useState('');
    const [providerId, setProviderId] = useState('');
    const [providers, setProviders] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchProviders = async () => {
            try {
                const providerList = await Api.listAllProviders();
                setProviders(providerList);
                if (providerList.length > 0) {
                    setProviderId(providerList[0].id);
                }
            } catch (error) {
                console.error('Error fetching providers:', error);
            }
        };
        fetchProviders();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            await onAdd(providerId, nickname, key);
            onClose();
            // Clear the state
            setNickname('');
            setKey('');
            setProviderId(providers.length > 0 ? providers[0].id : '');
        } catch (error) {
            console.error('Error adding API key:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Add New API Key">
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="nickname" className="block text-sm font-medium text-gray-700 mb-1">Nickname</label>
                    <input
                        type="text"
                        id="nickname"
                        value={nickname}
                        onChange={(e) => setNickname(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        required
                        autoComplete="off"
                    />
                </div>
                <div>
                    <label htmlFor="key" className="block text-sm font-medium text-gray-700 mb-1">API Key</label>
                    <textarea
                        id="key"
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 font-mono text-sm resize-none"
                        required
                        rows="3"
                        autoComplete="off"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck="false"
                        data-lpignore="true"
                        placeholder="Enter your API key here"
                    />
                </div>
                <div>
                    <label htmlFor="provider" className="block text-sm font-medium text-gray-700 mb-1">Provider</label>
                    <select
                        id="provider"
                        value={providerId}
                        onChange={(e) => setProviderId(e.target.value)}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        required
                    >
                        {providers.map((provider) => (
                            <option key={provider.id} value={provider.id}>{provider.name}</option>
                        ))}
                    </select>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                        type="submit"
                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Adding...' : 'Add API Key'}
                    </button>
                    <button
                        type="button"
                        onClick={onClose}
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </Modal>
    );
};

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm, apiKeyNickname }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title="Confirm Deletion">
            <p className="mb-6">Are you sure you want to delete the API key "{apiKeyNickname}"? This action cannot be undone.</p>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                    onClick={onConfirm}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Delete
                </button>
                <button
                    onClick={onClose}
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                    Cancel
                </button>
            </div>
        </Modal>
    );
};

const Settings = ({ onApiKeyChange }) => {
    const [apiKeys, setApiKeys] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedApiKey, setSelectedApiKey] = useState(null);
    const [toastMessage, setToastMessage] = useState(null);

    const handleResponseError = (error, fallbackMessage) => {
        const message = error.response?.data?.displayMessage || fallbackMessage || "Something went wrong. Please try again.";
        setToastMessage(message);
        setTimeout(() => setToastMessage(null), 5000);
    };

    const fetchApiKeys = async () => {
        try {
            const providers = await Api.listAllProviders();
            const keys = providers
                .filter(provider => provider.apiKeys.length > 0)
                .flatMap((provider) => provider.apiKeys.map(key => ({
                    id: key.id || 'N/A',
                    nickname: key.nickname || 'N/A',
                    key: key.maskedKey || 'N/A',
                    providerId: provider?.id,
                    provider: provider?.name || 'N/A'
                })));
            setApiKeys(keys);
            setIsLoading(false);
        } catch (error) {
            handleResponseError(error, 'Failed to load API keys. Please try again later.');
            setError('Failed to load API keys. Please try again later.');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchApiKeys();
    }, []);

    const handleAddApiKey = async (providerId, nickname, key) => {
        try {
            await Api.addApiKey(providerId, nickname, key);
            await fetchApiKeys();
            onApiKeyChange();
            setIsModalOpen(false);
        } catch (error) {
            handleResponseError(error, 'Failed to add API key. Please try again.');
        }
    };

    const handleDeleteClick = (apiKey) => {
        setSelectedApiKey(apiKey);
        setIsDeleteModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (selectedApiKey) {
            try {
                await Api.deleteApiKey(selectedApiKey.providerId, selectedApiKey.id);
                await fetchApiKeys();
                setIsDeleteModalOpen(false);
                setSelectedApiKey(null);
                onApiKeyChange();
            } catch (error) {
                handleResponseError(error, 'Failed to delete API key. Please try again.');
            }
        }
    };

    const renderApiKeyValue = (key) => {
        if (typeof key === 'string' && key.length > 8) {
            return `${key.substring(0, 4)}...${key.substring(key.length - 4)}`;
        }
        return 'N/A';
    };

    return (
        <div className="container mx-auto px-4 py-8 max-w-4xl">
            <div className="bg-white shadow-md rounded-lg overflow-hidden border border-gray-200">
                <div className="px-6 py-4 border-b border-gray-200 flex justify-between items-center">
                    <h2 className="text-xl font-semibold text-gray-800">API Keys</h2>
                    <button
                        className="flex items-center px-3 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-200"
                        onClick={() => setIsModalOpen(true)}
                    >
                        <PlusCircleIcon className="h-5 w-5 mr-2" />
                        Add New Key
                    </button>
                </div>
                <div className="p-6">
                    {isLoading ? (
                        <p className="text-center text-gray-600">Loading API keys...</p>
                    ) : error ? (
                        <p className="text-center text-red-600">{error}</p>
                    ) : apiKeys.length === 0 ? (
                        <p className="text-center text-gray-600">No API keys found.</p>
                    ) : (
                        <div className="overflow-x-auto shadow-md rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200">
                                <thead className="bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Nickname
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Key
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Provider
                                        </th>
                                        <th scope="col" className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {apiKeys.map((key) => (
                                        <tr key={key.id} className="hover:bg-gray-50 transition duration-150">
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-medium text-gray-900">{key.nickname}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-mono text-gray-900">{renderApiKeyValue(key.key)}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm text-gray-900">{key.provider}</div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap text-center">
                                                <button
                                                    className="text-red-600 hover:text-red-800 transition duration-150 p-1 rounded-full hover:bg-red-100"
                                                    onClick={() => handleDeleteClick(key)}
                                                >
                                                    <TrashIcon className="h-5 w-5" />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
            <AddApiKeyModal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onAdd={handleAddApiKey}
            />
            <ConfirmDeleteModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onConfirm={handleConfirmDelete}
                apiKeyNickname={selectedApiKey?.nickname}
            />
            <Toaster
                message={toastMessage}
                onClose={() => setToastMessage(null)}
            />
        </div>
    );
};

export default Settings;