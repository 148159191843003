import React from 'react';
import Modal from './Modal';

const ConfirmDeleteModal = ({ isOpen, onClose, onConfirm, itemName, itemType }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title={`Confirm ${itemType} Deletion`}>
      <p className="mb-6">
        Are you sure you want to delete the {itemType.toLowerCase()} "{itemName}"? This action cannot be undone.
      </p>
      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          onClick={onConfirm}
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          Delete
        </button>
        <button
          onClick={onClose}
          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:w-auto sm:text-sm"
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;