import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import ProjectView from './components/ProjectView';
import Login from './components/Login';
import PrivateRoute from './components/PrivateRoute';
import Documents from './components/Documents';
import Settings from './components/Settings';
import { AuthProvider, useAuth } from './context/AuthContext';
import useTemplates from './hooks/useTemplate';
import Api from './utils/api';

const AppContent = () => {
  const [projects, setProjects] = useState([]);
  const [inferenceProviders, setInferenceProviders] = useState([]);
  const { isAuthenticated } = useAuth();
  const { templates, saveTemplate, deleteTemplate, fetchTemplates } = useTemplates();

  const fetchInferenceProviders = useCallback(async () => {
    try {
      const providers = await Api.listAllProviders();
      const availableProviders = providers.filter(provider => provider.apiKeys.length > 0);
      setInferenceProviders(availableProviders);
    } catch (error) {
      console.error('Error fetching inference providers:', error);
    }
  }, []);

  const fetchProjects = useCallback(async () => {
    try {
      const data = await Api.getProjects();
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchTemplates();
      fetchProjects();
      fetchInferenceProviders();
    }
  }, [isAuthenticated, fetchTemplates, fetchInferenceProviders, fetchProjects]);

  const handleAddProject = async (name, description) => {
    try {
      const newProject = await Api.addProject(name, description);
      setProjects(prevProjects => [...prevProjects, newProject]);
      return newProject;
    } catch (error) {
      console.error('Error adding project:', error);
      throw error;
    }
  };

  return (
    <div className="flex flex-col h-screen">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Navigate to="/projects" replace />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects"
          element={
            <PrivateRoute>
              <div className="border-b border-gray-300">
                <Header projects={projects} onAddProject={handleAddProject} />
              </div>
              <div className="flex-1 overflow-hidden">
                <ProjectView
                  projects={projects}
                  setProjects={setProjects}
                  templates={templates}
                  inferenceProviders={inferenceProviders}
                  onAddProject={handleAddProject}
                />
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/:projectId"
          element={
            <PrivateRoute>
              <div className="border-b border-gray-300">
                <Header projects={projects} onAddProject={handleAddProject} />
              </div>
              <div className="flex-1 overflow-hidden">
                <ProjectView
                  projects={projects}
                  setProjects={setProjects}
                  templates={templates}
                  saveTemplate={saveTemplate}
                  deleteTemplate={deleteTemplate}
                  inferenceProviders={inferenceProviders}
                  onAddProject={handleAddProject}
                />
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/:projectId/workspaces/:workspaceId"
          element={
            <PrivateRoute>
              <div className="border-b border-gray-300">
                <Header projects={projects} onAddProject={handleAddProject} />
              </div>
              <div className="flex-1 overflow-hidden">
                <ProjectView
                  projects={projects}
                  setProjects={setProjects}
                  templates={templates}
                  saveTemplate={saveTemplate}
                  deleteTemplate={deleteTemplate}
                  inferenceProviders={inferenceProviders}
                  onAddProject={handleAddProject}
                />
              </div>
            </PrivateRoute>
          }
        />
        <Route
          path="/documents"
          element={
            <PrivateRoute>
              <div className="border-b border-gray-300">
                <Header projects={projects} onAddProject={handleAddProject} />
              </div>
              <Documents />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <div className="border-b border-gray-300">
                <Header projects={projects} onAddProject={handleAddProject} />
              </div>
              <Settings onApiKeyChange={fetchInferenceProviders} />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <AuthProvider>
        <AppContent />
      </AuthProvider>
    </Router>
  );
};

export default App;