// src/components/Documents.js

import React, { useState, useEffect } from 'react';
import { PlusIcon, SearchIcon, DocumentIcon } from '@heroicons/react/outline';
import Toaster from './Toaster';
import Api from '../utils/api';

const Documents = ({ isModal = false, onAttach = null }) => {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [toastMessage, setToastMessage] = useState(null);

  useEffect(() => {
    fetchDocuments();
  }, []);

  const fetchDocuments = async () => {
    setIsLoading(true);
    try {
      const fetchedDocuments = await Api.getDocuments();
      setDocuments(fetchedDocuments);
    } catch (error) {
      console.error('Error fetching documents:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const uploadedDocument = await Api.uploadDocument(file);

        const isDuplicate = documents.some(doc => doc.documentId === uploadedDocument.documentId);

        if (!isDuplicate) {
          setDocuments(prevDocuments => [...prevDocuments, uploadedDocument]);
        } else {
          setToastMessage('Document already exists.');
          setTimeout(() => setToastMessage(null), 5000);
        }
      } catch (error) {
        console.error('Error uploading document:', error);
      }
    }
  };

  const handleAttach = (document, event) => {
    event.stopPropagation();
    if (isModal && onAttach) {
      onAttach(document);
    }
  };

  const openDocument = async (documentId) => {
    try {
      const data = await Api.getDocument(documentId);
      const blob = new Blob([Uint8Array.from(atob(data.binaryData), c => c.charCodeAt(0))], { type: data.mimeType });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  const filteredDocuments = documents.filter(doc =>
    doc.fileName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div 
      className={`flex flex-col ${isModal ? 'h-96' : 'h-[calc(99vh-var(--header-height,64px))]'}`}
      style={isModal ? {} : { '--header-height': '64px' }}
    >
      <div className="p-4">
        <div className="mb-4 flex items-center">
          <div className="relative flex-grow">
            <input
              type="text"
              placeholder="Search documents..."
              value={searchTerm}
              onChange={handleSearch}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <SearchIcon className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
          </div>
          <label className="ml-4 cursor-pointer bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 transition duration-200">
            <input type="file" className="hidden" onChange={handleUpload} />
            <PlusIcon className="h-5 w-5 inline-block mr-2" />
            Upload
          </label>
        </div>
      </div>
      <div className="flex-grow overflow-y-auto p-4">
        {isLoading ? (
          <p className="text-center text-gray-600">Loading documents...</p>
        ) : filteredDocuments.length === 0 ? (
          <p className="text-center text-gray-600">No documents found.</p>
        ) : (
          <div className="border border-gray-200 rounded-md">
            {filteredDocuments.map((document, index) => (
              <div
                key={document.id}
                className={`flex items-center p-3 cursor-pointer hover:bg-gray-50 ${index !== filteredDocuments.length - 1 ? 'border-b border-gray-200' : ''}`}
                onClick={() => openDocument(document.documentId)}
              >
                <div className="flex-shrink-0 w-6 h-6 mr-3">
                  <DocumentIcon className="w-full h-full text-gray-400" />
                </div>
                <span className="flex-grow truncate">{document.fileName}</span>
                {isModal && (
                  <button
                    onClick={(e) => handleAttach(document, e)}
                    className="ml-2 p-1 text-gray-400 hover:text-blue-500 focus:outline-none"
                    title="Attach document"
                  >
                    <PlusIcon className="h-5 w-5" />
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <Toaster
        message={toastMessage}
        onClose={() => setToastMessage(null)}
      />
    </div>
  );
};

export default Documents;