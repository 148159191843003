import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { ArrowsExpandIcon, XIcon, UploadIcon, PaperClipIcon, LightningBoltIcon } from '@heroicons/react/outline';
import { DotsVerticalIcon, PaperAirplaneIcon, ChevronDownIcon } from '@heroicons/react/solid';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import Api from '../utils/api';
import TemplateModal from './TemplateModal';
import NoProvidersModal from './NoProvidersModal';
import TemplateMessageModal from './TemplateMessageModal';
import DocumentsModal from './DocumentsModal';

const Conversation = ({
  conversationId,
  projectId,
  workspaceId,
  isMaximized,
  onMaximize,
  onMinimize,
  onDeleteConversation,
  onCloneConversation,
  templates,
  saveTemplate,
  deleteTemplate,
  inferenceProviders,
}) => {
  const [conversation, setConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [imageLoadStatus, setImageLoadStatus] = useState({});
  const [inputAttachments, setInputAttachments] = useState([]);
  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [templateVariables, setTemplateVariables] = useState({});
  const [templateDocuments, setTemplateDocuments] = useState({});
  const [selectedModel, setSelectedModel] = useState('');
  const [isNoProvidersModalOpen, setIsNoProvidersModalOpen] = useState(false);
  const [isUpdatingModel, setIsUpdatingModel] = useState(false);
  const [toastMessage, setToastMessage] = useState(null);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [selectedTemplateMessage, setSelectedTemplateMessage] = useState(null);
  const [documentNames, setDocumentNames] = useState({});
  const [isDocumentsModalOpen, setIsDocumentsModalOpen] = useState(false);
  const [currentDocumentName, setCurrentDocumentName] = useState(null);

  const menuRef = useRef(null);
  const menuButtonRef = useRef(null);
  const messagesContainerRef = useRef(null);
  const inputRef = useRef(null);
  const conversationContainerRef = useRef(null);

  useEffect(() => {
    const fetchConversation = async () => {
      if (conversationId) {
        try {
          const data = await Api.getConversation(projectId, workspaceId, conversationId);
          setConversation(data);
          setMessages(data.messages);

          // Set the selectedModel if conversation.model is available
          if (data.model && data.model.id) {
            const provider = inferenceProviders.find(p =>
              p.models.some(m => m.id === data.model.id)
            );
            if (provider) {
              setSelectedModel(`${provider.id}-${data.model.id}`);
            }
          }
        } catch (error) {
          console.error('Error fetching conversation:', error);
        }
      }
    };

    fetchConversation();
  }, [projectId, workspaceId, conversationId, inferenceProviders]);

  useEffect(() => {
    if (inferenceProviders.length > 0 && inferenceProviders[0].models.length > 0) {
      const firstProvider = inferenceProviders[0];
      const firstModel = firstProvider.models[0];
      setSelectedModel(`${firstProvider.id}-${firstModel.id}`);
    }
  }, [inferenceProviders]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isMaximized && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isMaximized]);

  useLayoutEffect(() => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    if (messages.length > 0) {
      fetchDocumentNames();
    }
  }, [messages]);

  const fetchDocumentNames = async () => {
    const newDocumentNames = { ...documentNames };
    let hasNewDocuments = false;

    for (const message of messages) {
      if (message.template && message.template.documents) {
        for (const doc of message.template.documents) {
          const docId = Object.values(doc)[0];
          if (!newDocumentNames[docId]) {
            try {
              const data = await Api.getDocument(docId);
              newDocumentNames[docId] = data.fileName;
              hasNewDocuments = true;
            } catch (error) {
              console.error('Error fetching document name:', error);
              newDocumentNames[docId] = 'Unknown File';
            }
          }
        }
      }
    }

    if (hasNewDocuments) {
      setDocumentNames(newDocumentNames);
    }
  };

  const handleConversationClick = () => {
    setIsFocused(true);
  };

  const handleSendMessage = async () => {
    if (!isSendingAllowed() || isMessageEmpty()) return;

    const userMessage = createUserMessage();
    updateUIWithUserMessage(userMessage);
    clearInputFields();

    try {
      const messageToSend = prepareMessageForSending(userMessage);
      const response = await Api.sendMessageWithStream(projectId, workspaceId, conversationId, messageToSend);
      await processStreamedResponse(response);
    } catch (error) {
      console.error('Error sending message:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  const isMessageEmpty = () => inputMessage.trim() === '' && inputAttachments.length === 0;

  const createUserMessage = () => {
    const message = {
      role: 'user',
      content: inputMessage,
    };
    if (inputAttachments.length > 0) {
      message.attachments = inputAttachments.map(({ id, fileName }) => ({ id, fileName }));
    }
    return message;
  };

  const updateUIWithUserMessage = (message) => {
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  const clearInputFields = () => {
    setInputMessage('');
    setInputAttachments([]);
  };

  const prepareMessageForSending = (message) => ({
    ...message,
    attachments: message.attachments ? message.attachments.map(att => att.id) : undefined,
  });

  const processStreamedResponse = async (response) => {
    const reader = response.body.getReader();
    let assistantMessageContent = '';
    let isFirstChunk = true;

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      const chunk = new TextDecoder('utf-8').decode(value);
      const lines = chunk.split('\n');

      for (const line of lines) {
        if (line.startsWith('data:')) {
          const data = JSON.parse(line.slice(5));
          handleStreamedData(data, assistantMessageContent, isFirstChunk);
          if (data.type === 'content') {
            assistantMessageContent += data.data;
            isFirstChunk = false;
          }
        }
      }
    }
  };

  const handleStreamedData = (data, content, isFirst) => {
    if (data.type === 'message_start') {
      updateConversationId(data.data.conversationId);
    } else if (data.type === 'content') {
      updateAssistantMessage(content + data.data, isFirst);
    } else if (data.type === 'error') {
      handleErrorResponse();
    }
  };

  const updateConversationId = (id) => {
    setConversation((prev) => ({ ...prev, id }));
  };

  const updateAssistantMessage = (content, isFirst) => {
    setMessages((prevMessages) => {
      if (isFirst) {
        return [...prevMessages, { role: 'assistant', content }];
      } else {
        const updatedMessages = [...prevMessages];
        updatedMessages[updatedMessages.length - 1].content = content;
        return updatedMessages;
      }
    });
  };

  const handleErrorResponse = () => {
    setMessages((prevMessages) => prevMessages.slice(0, -1));
    setToastMessage("Something went wrong. Please try again.");
    setTimeout(() => {
      setToastMessage(null);
    }, 5000);
  };

  const isSendingAllowed = () => {
    return inferenceProviders.length > 0 && selectedModel !== '';
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && isSendingAllowed()) {
      if (selectedTemplate) {
        handleRunTemplate();
      } else {
        handleSendMessage();
      }
    }
  };

  const handleInputFocus = () => {
    setIsFocused(true);
  };

  const handleInputBlur = () => {
    setIsFocused(false);
  };

  const openTemplateModal = (message) => {
    setSelectedTemplateMessage(message);
    setTemplateModalOpen(true);
  };

  const closeTemplateModal = () => {
    setTemplateModalOpen(false);
    setSelectedTemplateMessage(null);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuButtonRef.current &&
      !menuButtonRef.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  const handleImageError = (index) => {
    setImageLoadStatus((prevStatus) => ({ ...prevStatus, [index]: 'error' }));
  };

  const handleImageLoad = (index) => {
    setImageLoadStatus((prevStatus) => ({ ...prevStatus, [index]: 'loaded' }));
  };

  function getMessageContent(message) {
    if (message.template) {
      return `Template id: ${message.template.id}, 
      Template vars: ${message.template.variables},
      Template docs: ${message.template.documents},
      Template name: ${message.template.name},
      content: ${message.content}`;
    }

    const content = message.content || message;

    if (typeof content === 'string') {
      return content;
    }

    if (Array.isArray(content)) {
      return content.map(item => item.text).join('\n');
    }

    if (content.content) {
      return content.content;
    }

    return JSON.stringify(content, null, 2);
  }

  const formatMessage = (message, messageIndex) => {
    if (message.template) {
      const hasVariables = message.template.variables.length > 0;
      const hasDocuments = message.template.documents.length > 0;
      const totalVars = message.template.variables.length;
      const totalDocs = message.template.documents.length;

      return (
        <div className="bg-gray-50 p-5 rounded-lg border border-gray-200 shadow-sm">
          <h4 className="font-semibold mb-3 text-md text-gray-800">{message.template.name}</h4>
          {hasVariables && (
            <div className="mb-4">
              <h5 className="font-semibold text-sm text-gray-600 mb-2">Variables:</h5>
              <div className="space-y-2">
                {message.template.variables.slice(0, 2).map((variable, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <div className="w-1/2 px-3 py-2 bg-white rounded-md border border-gray-200 text-sm">
                      {Object.keys(variable)[0]}
                    </div>
                    <div className="w-1/2 px-3 py-2 bg-white rounded-md border border-gray-200 text-sm">
                      {Object.values(variable)[0]}
                    </div>
                  </div>
                ))}
              </div>
              {totalVars > 2 && (
                <p className="text-xs text-gray-500 mt-2 italic">
                  +{totalVars - 2} more variable{totalVars - 2 > 1 ? 's' : ''}
                </p>
              )}
            </div>
          )}
          {hasDocuments && (
            <div className="mb-4">
              <h5 className="font-semibold text-sm text-gray-600 mb-2">Documents:</h5>
              <div className="space-y-2">
                {message.template.documents.slice(0, 2).map((doc, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <div className="w-1/2 px-3 py-2 bg-white rounded-md border border-gray-200 text-sm">
                      {Object.keys(doc)[0]}
                    </div>
                    <div className="w-1/2 flex items-center bg-white rounded-md border border-gray-200 overflow-hidden">
                      <button
                        onClick={() => openDocument(Object.values(doc)[0])}
                        className="flex-1 text-left text-blue-600 hover:text-blue-800 focus:outline-none truncate px-3 py-2 text-sm"
                      >
                        <PaperClipIcon className="h-4 w-4 inline-block mr-1" />
                        <span className="truncate">
                          {documentNames[Object.values(doc)[0]] || 'Loading...'}
                        </span>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              {totalDocs > 2 && (
                <p className="text-xs text-gray-500 mt-2 italic">
                  +{totalDocs - 2} more document{totalDocs - 2 > 1 ? 's' : ''}
                </p>
              )}
            </div>
          )}
          <button
            onClick={() => openTemplateModal(message)}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            View Details
          </button>
        </div>
      );
    }

    let messageContent = getMessageContent(message);

    return (
      <div className="prose max-w-none">
        {typeof messageContent === 'string' ? (
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            components={{
              code({ node, inline, className, children, ...props }) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    style={atomDark}
                    language={match[1]}
                    PreTag="div"
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={`${className} bg-gray-200 text-red-600 px-1 py-0.5 rounded-md font-mono text-sm`} {...props}>
                    {children}
                  </code>
                );
              },
              hr: ({ node, ...props }) => (
                <hr className="my-8 border-t-2 border-gray-300" {...props} />
              ),
              h1: ({ node, ...props }) => <h1 className="text-4xl font-bold mt-8 mb-4" {...props} />,
              h2: ({ node, ...props }) => <h2 className="text-3xl font-bold mt-6 mb-3" {...props} />,
              h3: ({ node, ...props }) => <h3 className="text-2xl font-bold mt-4 mb-2" {...props} />,
              h4: ({ node, ...props }) => <h4 className="text-xl font-bold mt-3 mb-1" {...props} />,
              h5: ({ node, ...props }) => <h5 className="text-lg font-bold mt-2 mb-1" {...props} />,
              h6: ({ node, ...props }) => <h6 className="text-base font-bold mt-1 mb-1" {...props} />,
              ul: ({ node, ...props }) => <ul className="list-disc ml-6 my-2" {...props} />,
              ol: ({ node, ...props }) => <ol className="list-decimal ml-6 my-2" {...props} />,
              li: ({ node, ...props }) => <li className="my-1" {...props} />,
              blockquote: ({ node, ...props }) => (
                <blockquote className="border-l-4 border-gray-300 pl-4 italic my-4" {...props} />
              ),
              a: ({ node, ...props }) => (
                <a className="text-blue-500 underline hover:text-blue-600" {...props} />
              ),
              strong: ({ node, ...props }) => <strong className="font-bold" {...props} />,
              em: ({ node, ...props }) => <em className="italic" {...props} />,
              del: ({ node, ...props }) => <del className="line-through" {...props} />,
              table: ({ node, ...props }) => <table className="table-auto border-collapse my-4" {...props} />,
              thead: ({ node, ...props }) => <thead className="bg-gray-100" {...props} />,
              tbody: ({ node, ...props }) => <tbody {...props} />,
              tr: ({ node, ...props }) => <tr className="border-b" {...props} />,
              th: ({ node, ...props }) => <th className="px-4 py-2 text-left" {...props} />,
              td: ({ node, ...props }) => <td className="px-4 py-2" {...props} />,
              img: ({ node, ...props }) => {
                const imageIndex = `${messageIndex}-${props.src}`;
                const status = imageLoadStatus[imageIndex];
                return status === 'error' ? (
                  <div className="my-4 bg-gray-200 text-gray-500 text-center p-4 rounded-md">
                    Image not available
                  </div>
                ) : (
                  <img
                    className="my-4"
                    onError={() => handleImageError(imageIndex)}
                    onLoad={() => handleImageLoad(imageIndex)}
                    {...props}
                  />
                );
              },
              p: ({ node, ...props }) => <p className="my-2" {...props} />,
              pre: ({ node, ...props }) => <pre className="my-4" {...props} />,
            }}
          >
            {messageContent}
          </ReactMarkdown>
        ) : (
          <pre>{JSON.stringify(messageContent, null, 2)}</pre>
        )}
      </div>
    );
  };

  const handleOpenDocumentsModal = (documentName = null) => {
    setIsDocumentsModalOpen(true);
    setCurrentDocumentName(documentName);
  };

  const handleCloseDocumentsModal = () => {
    setIsDocumentsModalOpen(false);
    setCurrentDocumentName(null);
  };

  const handleAttachDocument = (document) => {
    if (currentDocumentName) {
      setTemplateDocuments(prev => ({
        ...prev,
        [currentDocumentName]: { id: document.documentId, fileName: document.fileName }
      }));
    } else {
      setInputAttachments((prevAttachments) => [
        ...prevAttachments,
        { id: document.documentId, fileName: document.fileName }
      ]);
    }
    setIsDocumentsModalOpen(false);
    setCurrentDocumentName(null);
  };

  const handleRemoveAttachment = (index) => {
    setInputAttachments((prevAttachments) => {
      const updatedAttachments = [...prevAttachments];
      updatedAttachments.splice(index, 1);
      return updatedAttachments;
    });
  };

  const openDocument = async (documentId) => {
    try {
      const data = await Api.getDocument(documentId);
      const blob = new Blob([Uint8Array.from(atob(data.binaryData), c => c.charCodeAt(0))], { type: data.mimeType });
      const url = URL.createObjectURL(blob);
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  };

  const handleSelectTemplate = (templateName) => {
    const template = templates.find(t => t.name === templateName);
    if (template) {
      setSelectedTemplate(template);
      initializeTemplateFields(template);
    } else {
      setSelectedTemplate(null);
      setTemplateVariables({});
      setTemplateDocuments({});
    }
  };

  const initializeTemplateFields = (template) => {
    const variables = {};
    template.variables.forEach(v => {
      variables[v.name] = v.value || '';
    });
    setTemplateVariables(variables);

    const documents = {};
    template.documents.forEach(d => {
      documents[d.name] = { id: d.id || '', fileName: d.fileName || '' };
    });
    setTemplateDocuments(documents);
  };

  const handleSaveTemplate = (newTemplate) => {
    saveTemplate(newTemplate);
    setIsTemplateModalOpen(false);
    setSelectedTemplate(newTemplate);
    initializeTemplateFields(newTemplate);
  };

  const handleDeleteTemplate = (templateName) => {
    deleteTemplate(templateName);
    if (selectedTemplate === templateName) {
      setSelectedTemplate('');
      setTemplateVariables({});
      setTemplateDocuments({});
    }
  };

  const handleVariableChange = (name, value) => {
    setTemplateVariables(prev => ({ ...prev, [name]: value }));
  };

  const handleRemoveDocument = (documentName) => {
    setTemplateDocuments(prev => {
      const updated = { ...prev };
      delete updated[documentName];
      return updated;
    });
  };

  const handleDocumentChange = async (name, file) => {
    try {
      const result = await Api.uploadDocument(file);
      setTemplateDocuments(prev => ({
        ...prev,
        [name]: { id: result.documentId, fileName: result.fileName }
      }));
    } catch (error) {
      console.error('Error uploading document:', error);
      // Handle error (e.g., show an error message to the user)
    }
  };

  // Add this function to replace variables in the prompt
  const replaceVariablesInPrompt = (prompt, variables) => {
    return prompt.replace(/\{(\w+)\}/g, (match, variable) => {
      return variables[variable] || match;
    });
  };

  const resetInputState = () => {
    setSelectedTemplate(null);
    setTemplateVariables({});
    setTemplateDocuments({});
  };

  const handleRunTemplate = () => {
    if (!isSendingAllowed() || !selectedTemplate) return;
    if (selectedTemplate) {
      const template = templates.find(t => t.name === selectedTemplate.name);

      if (template) {
        const userMessage = {
          role: 'user',
          template: {
            id: template.id,
            name: template.name, // Include name for local state
            variables: Object.entries(templateVariables).map(([key, value]) => ({ [key]: value })),
            documents: Object.entries(templateDocuments)
              .filter(([_, doc]) => doc.id)
              .map(([key, doc]) => ({ [key]: doc.id }))
          },
          // Generate content for UI display
          content: replaceVariablesInPrompt(template.prompt, templateVariables)
        };

        // Update local UI state with the full message including template name and content
        updateUIWithUserMessage(userMessage);
        clearInputFields();

        // Create a copy of the message without the template name and content for sending to the backend
        const messageForBackend = {
          ...userMessage,
          template: {
            ...userMessage.template,
            name: undefined // Remove name before sending to backend
          },
          content: undefined // Remove content before sending to backend
        };

        sendTemplateMessage(messageForBackend);
        resetInputState();
      }
    }
  };

  const sendTemplateMessage = async (userMessage) => {
    try {
      const response = await Api.sendMessageWithStream(projectId, workspaceId, conversationId, userMessage);
      await processStreamedResponse(response);
    } catch (error) {
      console.error('Error sending template message:', error);
    }
  };

  const updateConversationModel = async (providerId, modelId) => {
    setIsUpdatingModel(true);
    try {
      await Api.patchConversation(projectId, workspaceId, conversationId, {
        providerId: Number(providerId),
        modelId: Number(modelId),
      });
    } catch (error) {
      console.error('Error updating conversation model:', error);
    } finally {
      setIsUpdatingModel(false);
    }
  };

  const handleModelChange = (e) => {
    const [providerId, modelId] = e.target.value.split('-');
    setSelectedModel(`${providerId}-${modelId}`);
    updateConversationModel(providerId, modelId);
  };

  const renderModelSelection = () => (
    <div className="relative inline-flex items-center">
      {inferenceProviders.length > 0 ? (
        <>
          <select
            value={selectedModel}
            onChange={handleModelChange}
            disabled={isUpdatingModel}
            className={`appearance-none bg-white text-gray-700 py-2 pl-2 pr-8 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm ${isUpdatingModel ? 'opacity-50 cursor-not-allowed' : ''
              }`}
          >
            {inferenceProviders.map((provider) => (
              <optgroup key={provider.id} label={provider.name}>
                {provider.models.map((model) => (
                  <option key={model.id} value={`${provider.id}-${model.id}`}>
                    {model.displayName}
                  </option>
                ))}
              </optgroup>
            ))}
          </select>
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <ChevronDownIcon className="h-4 w-4" />
          </div>
        </>
      ) : (
        <button
          onClick={() => setIsNoProvidersModalOpen(true)}
          className="bg-gray-100 text-gray-600 py-2 px-3 rounded-md border border-gray-300 text-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          No Models Available
        </button>
      )}
    </div>
  );

  const renderInputArea = () => {
    if (selectedTemplate) {
      return (
        <div
          className="space-y-4 bg-gray-50 p-4 rounded-lg"
          onKeyPress={handleKeyPress}>
          <div className="flex items-center justify-between mb-4">
            <select
              value={selectedTemplate.name}
              onChange={(e) => handleSelectTemplate(e.target.value)}
              className="flex-grow px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
            >
              <option value="">No template</option>
              {templates.map((template, index) => (
                <option key={index} value={template.name}>
                  {template.name}
                </option>
              ))}
            </select>
            <button
              onClick={() => setIsTemplateModalOpen(true)}
              className="ml-2 p-2 bg-blue-100 text-blue-600 rounded-md hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <LightningBoltIcon className="h-5 w-5" />
            </button>
          </div>
          {selectedTemplate.variables.map((variable, index) => (
            <div key={index} className="flex flex-col">
              <label htmlFor={`var-${variable.name}`} className="text-sm font-medium text-gray-700 mb-1">
                {variable.name}
              </label>
              <input
                type="text"
                id={`var-${variable.name}`}
                value={templateVariables[variable.name] || ''}
                onChange={(e) => handleVariableChange(variable.name, e.target.value)}
                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 text-sm"
              />
            </div>
          ))}
          {selectedTemplate.documents.map((document, index) => (
            <div key={index} className="flex flex-col">
              <label htmlFor={`doc-${document.name}`} className="text-sm font-medium text-gray-700 mb-1">
                {document.name}
              </label>
              <div className="flex">
                {templateDocuments[document.name]?.id ? (
                  <div className="flex-grow flex items-center bg-gray-100 px-3 py-2 rounded-md overflow-hidden">
                    <button
                      onClick={() => openDocument(templateDocuments[document.name].id)}
                      className="flex-1 text-left text-blue-600 hover:text-blue-800 focus:outline-none truncate"
                    >
                      <PaperClipIcon className="h-5 w-5 inline-block mr-1" />
                      <span className="text-sm truncate">{templateDocuments[document.name].fileName}</span>
                    </button>
                    <button
                      onClick={() => handleRemoveDocument(document.name)}
                      className="ml-2 text-gray-400 hover:text-gray-600 focus:outline-none"
                    >
                      <XIcon className="h-5 w-5" />
                    </button>
                  </div>
                ) : (
                  <div className="flex-grow">
                    <button
                      onClick={() => handleOpenDocumentsModal(document.name)}
                      className="w-full inline-flex justify-center items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Select Document
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
          <div className="flex items-center justify-between">
            {renderModelSelection()}
            <button
              onClick={handleRunTemplate}
              disabled={!isSendingAllowed()}
              className={`ml-2 px-4 py-2 rounded-md text-sm font-medium ${isSendingAllowed()
                ? 'bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500'
                : 'bg-gray-300 text-gray-500 cursor-not-allowed'
                }`}
            >
              Run Template
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className="flex items-center">
          <div className="relative inline-flex items-center mr-2">
            <select
              value=""
              onChange={(e) => handleSelectTemplate(e.target.value)}
              className="appearance-none bg-gray-200 text-gray-700 py-2 pl-2 pr-8 rounded-l-md border-t border-b border-l border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
            >
              <option value="">No template</option>
              {templates.map((template, index) => (
                <option key={index} value={template.name}>
                  {template.name}
                </option>
              ))}
            </select>
            <button
              onClick={() => setIsTemplateModalOpen(true)}
              className="p-2 bg-gray-200 text-gray-700 rounded-r-md border border-gray-300 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <LightningBoltIcon className="h-5 w-5" />
            </button>
          </div>
          <button
            onClick={() => handleOpenDocumentsModal()}
            className="p-2 bg-gray-200 text-gray-700 rounded-md border border-gray-300 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 mr-2"
          >
            <PaperClipIcon className="h-5 w-5" />
          </button>
          <input
            type="text"
            ref={inputRef}
            placeholder="Type your message..."
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            className="flex-1 px-4 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <div className="relative inline-flex items-center ml-2">
            {renderModelSelection()}
            <button
              onClick={handleSendMessage}
              disabled={!isSendingAllowed()}
              className={`p-2 ${isSendingAllowed() ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'} text-white rounded-r-md border ${isSendingAllowed() ? 'border-blue-600' : 'border-gray-300'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
            >
              <PaperAirplaneIcon className="h-5 w-5 transform rotate-90" />
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div
      ref={conversationContainerRef}
      className={`flex flex-col border border-gray-300 rounded-lg shadow-md ${isMaximized ? 'absolute inset-0 z-50 bg-white' : 'mx-8 mb-12 bg-white'
        }`}
      onClick={handleConversationClick}
    >
      <div className="px-6 py-4 border-b border-gray-300 relative flex items-center justify-between">
        <h3 className="text-xl font-semibold">{conversation?.title}</h3>
        <div className="flex items-center space-x-4">
          {isMaximized ? (
            <button
              onClick={() => onMinimize()}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <XIcon className="h-6 w-6" />
            </button>
          ) : (
            <button
              onClick={() => onMaximize(conversation.id)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <ArrowsExpandIcon className="h-6 w-6" />
            </button>
          )}
          <div className="relative">
            <button
              ref={menuButtonRef}
              className="text-gray-500 hover:text-gray-700 focus:outline-none flex items-center"
              onClick={toggleMenu}
            >
              <DotsVerticalIcon className="h-6 w-6" />
            </button>
            {isMenuOpen && (
              <div
                ref={menuRef}
                className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-md shadow-lg z-10"
                style={{
                  top: '100%',
                  right: '-0.75rem',
                }}
              >
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  onClick={() => onDeleteConversation(conversation.id)}
                >
                  Delete Conversation
                </button>
                <button
                  className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  onClick={() => onCloneConversation(conversation.id)}
                >
                  Clone Conversation
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={`${isMaximized ? 'flex-1 overflow-y-auto p-6' : 'px-6 py-8'}`}>
        {messages.length === 0 ? (
          <div className="text-center text-gray-500">
            <p className="text-lg">No messages yet.</p>
            <p className="text-base">Start a conversation!</p>
          </div>
        ) : (
          <div
            ref={messagesContainerRef}
            className={`${isFocused || isMaximized ? 'overflow-y-auto' : 'overflow-y-hidden'} ${isMaximized ? 'h-full' : 'max-h-96'
              }`}
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`my-4 p-4 rounded-lg ${message.role === 'user' ? 'bg-blue-100' : 'bg-gray-100'
                  }`}
              >
                <div className="text-sm font-medium mb-2">
                  {message.role === 'user' ? 'You' : 'Assistant'}
                </div>
                <div className="prose max-w-none">{formatMessage(message, index)}</div>
                {message.attachments && message.attachments.length > 0 && (
                  <div className="mt-4">
                    <ul className="flex flex-wrap gap-2">
                      {message.attachments.map((attachment, attachmentIndex) => (
                        <li
                          key={attachmentIndex}
                          className="bg-white border border-gray-300 rounded-md px-2 py-1 flex items-center cursor-pointer hover:bg-gray-100"
                          onClick={() => openDocument(typeof attachment === 'string' ? attachment : attachment.id)}
                        >
                          <PaperClipIcon className="h-4 w-4 mr-2 text-gray-500" />
                          <span className="text-xs text-gray-700 truncate max-w-xs">
                            {typeof attachment === 'string' ? attachment : attachment.fileName || attachment.id}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="px-6 py-4 bg-gray-100 border-t border-gray-300">
        {inputAttachments.length > 0 && (
          <div className="mb-4">
            <ul className="flex flex-wrap">
              {inputAttachments.map((attachment, index) => (
                <li
                  key={index}
                  className="flex items-center bg-gray-200 text-gray-700 px-2 py-1 rounded-md mr-2 mb-2"
                >
                  <span
                    className="text-xs mr-2 cursor-pointer hover:underline"
                    onClick={() => openDocument(attachment.id)}
                  >
                    {attachment.fileName}
                  </span>
                  <button
                    onClick={() => handleRemoveAttachment(index)}
                    className="text-gray-500 hover:text-gray-700 focus:outline-none"
                  >
                    <XIcon className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {renderInputArea()}
      </div>

      {toastMessage && (
        <div
          onClick={() => setToastMessage(null)}
          className="fixed bottom-4 right-4 z-50 bg-red-500 text-white px-4 py-2 rounded-md shadow-lg transition-opacity duration-300 cursor-pointer"
        >
          {toastMessage}
        </div>
      )}

      <TemplateModal
        isOpen={isTemplateModalOpen}
        onClose={() => setIsTemplateModalOpen(false)}
        onSave={handleSaveTemplate}
        onDeleteTemplate={handleDeleteTemplate}
        templates={templates}
        selectedTemplate={selectedTemplate ? selectedTemplate.name : ''}
      />
      <NoProvidersModal
        isOpen={isNoProvidersModalOpen}
        onClose={() => setIsNoProvidersModalOpen(false)}
      />
      <TemplateMessageModal
        isOpen={templateModalOpen}
        onClose={closeTemplateModal}
        message={selectedTemplateMessage}
      />
      <DocumentsModal
        isOpen={isDocumentsModalOpen}
        onClose={handleCloseDocumentsModal}
        onAttach={handleAttachDocument}
      />
    </div>
  );
};

export default Conversation;